<template lang='pug'>
head-meta(title-page="Восстановление доступ" )
the-forgot-password
</template>

<script>
import HeadMeta from '../components/_layout/HeadMeta'
import TheForgotPassword from '../components/forgot-password/TheForgotPassword'
export default {
  name: 'ForgotPasswordPage',
  components: { TheForgotPassword, HeadMeta }
}
</script>
