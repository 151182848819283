<template lang='pug'>
.login
  .login__cont.cont
    template(v-if="!done")
      h1.login__title.title Восстановление пароля
      form.login__form(@submit.prevent="sendForm")
        label.login__label Почта
          input.login__input.text.input(
            v-model="email",
            type="email",
            placeholder="mail@mail.ru",
            :class="{'input_wrong' : error}"
            required
          )
          span.login__error-text.text-error(v-if="error") Пользователь не существует
        .login__button-box
          button.login__button.button-orange.text(:disabled="disabledSubmitButton") Восстановить пароль
        ul.login__list
          li.login__item
            span.login__item-text Уже есть аккаунт?
            router-link.login__link.link_gray(:to= "{ name: 'Login' }") Войти
    template(v-else)
      h1.login__caption.caption Проверьте почту
      p.login__text.text-big
        | Отправили пароль на почту
        span.login__text-bold {{ email }}
      .login__link-box
        router-link.login__link-login.button-orange.text(:to= "{ name: 'Login' }") Войти
</template>

<script>
import '@/assets/styles/components/login.sass'
import { passwordForgot } from '../../assets/scripts/Api'

export default {
  name: 'TheForgotPassword',
  data () {
    return {
      done: false,
      email: '',
      error: false,
      disabledSubmitButton: false
    }
  },
  methods: {
    async sendForm () {
      this.disabledSubmitButton = true
      const res = await passwordForgot(this.email)
      if (res.status === 'success') this.done = true
      else this.error = true
      this.disabledSubmitButton = false
    }
  }
}
</script>
